@import "../variables.scss";
@import "../mixins.scss";

.ticket-card {
    $self: &;

    @include card();
    
    margin: 20px 0 10px;

    display: flex;

    &--listed {
        margin: 10px 0;
        min-height: 150px;

        &:first-of-type {
            margin-top: 0;
        }
    }

    &--error {
        #{ $self } {
            &__thumbnail {
                display: none;
            }

            &__thumbnail-wrapper {
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: $white;
                border: $button-border;

                svg {
                    height: 30px;
                    color: $orange;
                }
            }
        }
    }

    &__event-copy {
        flex-shrink: 0;
        display: flex;
        flex-direction: column;
        width: 58%;
        padding-left: 12px;
    }

    &__event-detail {
        display: inline-block;

        &--expiration-date {
            margin-top: auto;
            font-size: 14px;
            font-style: italic;
            color: $color__copy--medium;
        }
    }

    &__event-header {
        margin: 0;
        font-size: 22px;
    }

    &__event-date {
        margin: 0;
        color: $color__copy--medium;

        &:first-of-type {
            margin: 10px 0 0;
        }

        &:last-of-type {
            margin: 0 0 10px;
        }
    }

    &__thumbnail-wrapper {
        flex: 1 0 auto;
        width: 42%;
        overflow: hidden;
        max-height: 125px;
    }

    &__thumbnail {
        height: 100%;
        // margin: 5px 15px 15px 0;
    }
}