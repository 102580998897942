.home {
    margin-bottom: 20px;
    
    &__content {
        font-family: Calibre, sans-serif;
		font-size: 18px;
		text-decoration: none;

        padding: 0 20px 0px 20px;
		margin-bottom: 20px;
	}
	
	&__text {
		p {
            color: $black;
            
            &:first-of-type {
                margin-top: 0;
            }

            &:last-of-type {
                margin-bottom: 0;
            }
        }

		padding: 20px 0;
        border-bottom: 1px solid $grey;

        &:first-of-type {
            padding-top: 0;
        }
        
        &:last-of-type {
            border-bottom: none;
        }
	}

    &__link {
        display: inline-block;
    }

    &__image {
        width: 100%;
    }

    &__header {
        text-decoration: underline;
        line-height: 1.2;
        font-weight: 700;
        font-size: 25px;
        margin: 5px 0 10px 0;
    }
}