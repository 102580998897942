@import "../variables.scss";

.title-bar {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  z-index: map-get($z-indexes, "title-bar");
  background-color: transparent;
  padding-bottom: 15px;
  height: #{map-get($headerHeights, "xsmall")}; /* 1 */

  @include breakpoint("medium+") {
    height: #{map-get($headerHeights, "medium")}; /* 1 */
  }

  @include breakpoint("large+") {
    height: #{map-get($headerHeights, "large")}; /* 1 */
    padding-top: 0;
  }

  @include breakpoint("xlarge+") {
    height: #{map-get($headerHeights, "xlarge")}; /* 1 */
    padding-top: 2px;
  }
}
