@import "../variables.scss";
@import "../mixins.scss";

@mixin artwork-of-the-day-flex-child {
  flex: 1;
  overflow: hidden;

  display: flex;
  flex-direction: column;
  justify-content: center;
}

.artwork-of-the-day {
  height: 350px;
  display: flex;

  flex-direction: column;
  // overflow: hidden;

  &__content {
    @include artwork-of-the-day-flex-child();
  }

  &__opacity-wrapper {
    @include artwork-of-the-day-flex-child();

    transition: opacity 1s;
  }

  &__link {
    @include artwork-of-the-day-flex-child();

    width: 100%;
  }

  &__image-card {
    @include artwork-of-the-day-flex-child();

    background-color: $color__grey--light;
    padding: 15px 15px 0;

    position: relative;

    &::after {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
    }
  }

  &__image-wrapper {
    max-width: 100%;
    max-height: 100%;

    margin: auto;
    overflow: hidden;
  }

  &__image {
    transition: opacity 0.5s;
  }

  &__subtitle {
    @include zeta();

    padding: 5px 0;
    width: 100%;
    text-align: center;
    display: block;
    margin-top: auto;
  }

  &__title {
    width: 100%;
    margin: 5px 0 10px 0;
    text-decoration: underline;
  }

  &__short-description {
    width: 100%;

    & > * {
      width: 90%;
      margin: 0;

      font-style: italic;
      color: $color__copy--medium !important;

      overflow: hidden;
      display: inline-block;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
}
