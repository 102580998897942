@import "../variables.scss";

.modal {
  background-color: rgba(220, 220, 220, 0.5);
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  z-index: #{map-get($z-indexes, "modal")};

  &__container {
    background-color: $white;
    opacity: 1;
    padding: 50px;
    margin: 16px;
  }

  &__content {
    clear: both;
  }

  &__close {
    background-color: transparent;
    border: none;
    float: right;
    margin-bottom: 16px;

    svg {
      color: $black;
      width: 30px;
      height: 22px;
    }
  }

  &__footer {
    margin: 0 auto;
    width: fit-content;
    display: flex;
    flex-direction: column;

    .btn-barnes {
      width: 100%;
    }
  }
}
