@import "../variables.scss";
@import "../mixins.scss";

.carousel-item-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 320px;

  @include breakpoint("medium+") {
    width: 400px;
    height: 450px;
  }
}

.carousel-item:hover,
.carousel-item__flag:hover {
  transform: scale(1.05);
}

.level-flag {
  position: relative;
  top: -20px;
  left: -20px;
  padding: 5px;
  height: 30px;
  width: 100px;
  background-color: $color__red--barnes;
  color: $white;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}

.carousel-item {
  $self: &;
  background-color: $grey;
  transition: transform 0.25s ease;
  box-shadow: -1px 3px 3px rgba(0, 0, 0, 0.15);

  border: $button-border;

  margin-left: 16px;
  margin-right: 16px;
  padding: 20px 20px 0 20px;
  max-width: calc(100% - 40px);

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  width: 300px;
  height: 400px;

  @include breakpoint("medium+") {
    width: 375px;
    height: 450px;
  }

  &:first-of-type {
    margin-left: 20px;
  }

  &:last-of-type {
    margin-right: 20px;
  }

  .offer-code {
    font-size: 12px;
    text-align: right;
    p {
      margin: 0;
      small {
        font-size: 12px;
      }
    }
  }

  &__content {
    height: 275px;
    @include breakpoint("medium+") {
      height: 350px;
    }
  }

  &__content-flag {
    height: 250px;
    @include breakpoint("medium+") {
      height: 325px;
    }
  }

  &__header {
    height: 10%;
    display: flex;
    justify-content: space-between;
    h2 {
      font-size: 20px;
      font-weight: 600;

      @include breakpoint("medium+") {
        font-size: 24px;
      }
    }

    h4 {
      font-size: 16px;
      font-weight: 600;

      @include breakpoint("medium+") {
        font-size: 20px;
      }
    }
  }

  &__body {
    overflow-y: auto;
    padding: 8px;
    height: 90%;
    p,
    li {
      font-size: 14px;
      list-style: square;
      font-family: Calibre, sans-serif;
      @include breakpoint("medium+") {
        font-size: 16px;
      }
    }

    &::-webkit-scrollbar {
      width: 6px;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      min-height: 120px;
      background-color: $color__copy--medium;
      border: solid 4px $color__copy--medium;
    }

    --scrollbar-width: 6px;
    --mask-height: 12px;
    padding-top: var(--mask-height);
    padding-bottom: var(--mask-height);
    --mask-image-content: linear-gradient(
      to bottom,
      transparent,
      black var(--mask-height),
      black calc(100% - var(--mask-height)),
      transparent
    );

    --mask-size-content: calc(100% - var(--scrollbar-width)) 100%;
    --mask-image-scrollbar: linear-gradient(black, black);
    --mask-size-scrollbar: var(--scrollbar-width) 100%;

    mask-image: var(--mask-image-content), var(--mask-image-scrollbar);
    mask-size: var(--mask-size-content), var(--mask-size-scrollbar);
    mask-position: 0 0, 100% 0;
    mask-repeat: no-repeat, no-repeat;
  }

  &__footer {
    display: flex;

    justify-content: center;
    align-items: center;

    .btn-barnes {
      margin: 0 0 16px 0;
    }
  }

  &__selected-level {
    transform: scale(1.05);
    background-color: $color__grey--light;
  }

  h2,
  h3,
  h4,
  p {
    margin: 0;
  }
}
