@import "../variables.scss";
@import "../mixins.scss";

.check-in {
  $self: &;

  margin-top: 5px;

  @include breakpoint("small+") {
    padding: 10px 20px 0;
  }
  

  &__availability {
    font-size: 18px;
    text-align: center;
    display: block;

    &.qualifies {
      color: $green;
    }

    &.n_qualifies {
      color: $color__red--barnes;
    }

    &.kiosk {
      font-size: 24px;
      color: $white;
      padding: 8px;
      font-weight: 800;
    }
  }

  &__ticket-type {
    display: flex;
    align-items: stretch;

    padding-top: 20px;
    padding-bottom: 20px;

    border-top: $border-space;

    &:first-of-type {
      border: none;
      padding-top: 10px;
    }

    &--guest-pass-error {
      #{ $self } {
        &__counter-button,
        &__counter-input {
          border-color: $orange;
        }

        &__counter-input {
          color: $orange;
        }
      }
    }

    &--max-quantity-error {
      #{ $self } {
        &__counter-button--plus,
        &__counter-input {
          color: $color__copy--medium;
          border-color: $grey;
          background-color: $grey;

          &:active,
          &:hover {
            cursor: default;
            color: $color__copy--medium;
            border-color: $grey;
            background-color: $grey;
          }
        }
      }
    }

    &.kiosk {
      position: relative;
      line-height: 2;
      width: 100%;
      font-size: 110%;
      // margin-bottom: 10px;
      padding: 18px;
      background-color: #f7f7f7;
      border: 1px solid #c6c2b9;
      min-height: 132px;
    }
  }

  &__ticket-type-child {
    flex: 1 1 100%;
    &.kiosk {
      font-size: 27px;
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;
    }
  }

  // Input labels and fields
  &__field {
    display: block;
    width: 100%;
  }

  &__name {
    font-weight: 700;
  }

  &__description {
    color: $orange;
    &.kiosk {
      color: $black;
      font-size: 14px;
      line-height: 14px;
    }
  }

  &__price-wrapper {
    height: 100%;
    display: flex;
    align-items: center;

    text-align: right;
    font-weight: 700;
  }

  &__price {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  &__x-out-price {
    text-decoration: line-through;
    display: inline-block;
    margin-right: 5px;
  }

  &__discount {
    @include zeta();

    display: block;
  }

  &__counter {
    display: flex;
    margin-top: 10px;
    &.kiosk {
      height: 100%;
      width: 100%;
      margin: 4px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
    }
  }

  &__counter-button {
    // Disable double tap zoom.
    touch-action: manipulation;

    background-color: $white;
    border: $button-border;
    padding: 0;

    color: $color__copy--medium;
    font-family: Calibre, sans-serif;
    font-weight: 700;
    font-size: 22px;

    height: 25px;
    width: 25px;
    margin-top: 10px;
    &.kiosk {
      background-color: transparent;
      width: 3vh;
      height: 3vh;
      margin: 1px;
      display: flex;
      justify-content: center;
      align-items: center;

      &:hover {
        background-color: $orange;
      }
      transition: color 0.5s, background-color 0.5s;
      &:after,
      &:before {
        background-color: $white;
        color: $white;
      }

      &:disabled {
        &:hover {
          background-color: unset;
        }

        .check-in__counter-button-content {
          color: $darkgrey;
        }
      }
    }
  }

  &__counter-button-content {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-content: center;
    line-height: 23px;

    &--minus {
      line-height: 22px;
    }
    &.kiosk {
      display: flex;
      justify-content: center;
      align-items: center;
      color: $black;
      &:hover {
        color: $white;
      }
    }
  }

  &__counter-input {
    // Remove iOS styling.
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: 0;

    border: $button-border;
    text-align: center;

    font-size: 171%;
    max-width: 80px;
    margin: 0 3px;

    height: 45px;
    &.kiosk {
      height: 100%;
      width: 50%;
      background-color: $white;
      padding: 0 12px 0 12px;
      font-size: 18px;
      border: $black;
      font-weight: bold;
      text-align: center;
      color: $black;
      @include breakpoint("medium+") {
        font-size: 24px;
        padding: 0 20px 0 20px;
      }
    }
  }

  &__error-message {
    @include zeta();

    font-weight: 700;
    color: $orange;

    &--margin-top {
      margin-top: 5px;
    }
    
    &.kiosk {
      position: relative;
      line-height: 2;
      width: 100%;
      font-size: 110%;
      padding: 18px;
      background-color: #f7f7f7;
      border: 1px solid #c6c2b9;
      margin: unset;
    }
  }

  &__spinner {
    margin: 0 !important;

    div {
      background-color: $white !important;
    }
  }

  &__disclaimer {
    width: 100%;
    margin-bottom: 25px;

    text-align: left;

    p {
      @include zeta();
      margin: 10px 0;

      @include breakpoint("medium+") {
        margin: 0;

        &:first-of-type {
          margin-top: 10px;
        }
      }
    }
    &.kiosk {
      display: flex;
      align-items: flex-end;
      justify-content: flex-end;
    }
  }

  &__total {
    width: 100%;
    text-align: right;
    font-size: 27px;
    font-weight: 700;
    &.kiosk {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      font-size: 45px;
      font-weight: 800;
      width: auto;
      height: 4vh;
      color: $white;
      // if visibility is bad add this background color
      // background-color: #f7f7f7;
      // border: 1px solid #c6c2b9;
      padding: 20px;
    }
  }

  &__copy {
    font-size: 18px;
    font-weight: 200;
  }

  &__value-proposition {
    // margin-bottom: 20px;
  }
}

.complete-booking-btn {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  &.kiosk {
    justify-content: space-between;
    position: fixed;
    bottom: 1rem;
    left: 50%;
    transform: translate(-50%, 0);
    width: 890px;
  }
  .btn-barnes-secondary {
    background-color: $white;
  }
}
