// @see https://codepen.io/gilbarbara/pen/pvwmEb
@mixin jagged(
  $color: 000,
  $size: 16,
  $location: bottom,
  $type: fill,
  $side: outside,
  $animate: false
) {
  $width: $size;
  $height: $width / 2;
  $scale: $width / 16;
  $rotate: "0";
  $pseudo: if($location == top or $location == left, before, after);

  @if $side == inside {
    @if $location == bottom {
      $rotate: "180 8 4";
    } @else if $location == left {
      $rotate: "270 8 8";
      $height: $size;
      $width: $size / 2;
    } @else if $location == right {
      $rotate: "90 4 4";
      $height: $size;
      $width: $size / 2;
    }
  } @else {
    @if $location == top {
      $rotate: "180 8 4";
    } @else if $location == right {
      $rotate: "270 8 8";
      $height: $size;
      $width: $size / 2;
    } @else if $location == left {
      $rotate: "90 4 4";
      $height: $size;
      $width: $size / 2;
    }
  }
  $arrow: "data:image/svg+xml,%3Csvg%20width%3D%22#{$width}px%22%20height%3D%22#{$height}px%22%20version%3D%221.1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpolygon%20points%3D%220%2C%200%208%2C%208%2016%2C0%22%20fill%3D%22%23#{$color}%22%20transform%3D%22scale%28#{$scale}%29%20rotate%28#{$rotate}%29%22%3E%3C%2Fpolygon%3E%3C%2Fsvg%3E";
  $wave: "data:image/svg+xml,%3Csvg%20width%3D%22#{$width}px%22%20height%3D%22#{$height}px%22%20version%3D%221.1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20d%3D%22M8.00025863%2C0%20L0%2C6.59417725%20L0%2C8%20L8.00025885%2C1.40509033%20L16%2C8%20L16%2C6.59417725%20L8.00025863%2C0%20Z%22%20fill%3D%22%23#{$color}%22%20transform%3D%22scale%28#{$scale}%29%20rotate%28#{$rotate}%29%22%3E%3C%2Fpath%3E%3C%2Fsvg%3E";
  position: relative;

  &:#{$pseudo} {
    content: "";
    position: absolute;
    z-index: #{map-get($z-indexes, "title-bar")};

    @if $animate {
      animation-duration: $animate;
      animation-name: if(
        $location == bottom or $location == top,
        slide-hoz,
        slide-vet
      );
      animation-iteration-count: 1;
    }

    @if $location == top {
      height: #{$height}px;
      left: 0;
      right: 0;
      @if $side == inside {
        top: 0;
      } @else {
        top: -#{$height}px;
      }
    } @else if $location == right {
      width: #{$width}px;
      top: 0;
      bottom: 0;
      @if $side == inside {
        right: 0;
      } @else {
        right: -#{$width}px;
      }
    } @else if $location == bottom {
      height: #{$height}px;
      left: 0;
      right: 0;
      @if $side == inside {
        bottom: 0;
      } @else {
        bottom: -#{$height}px;
      }
    } @else if $location == left {
      width: #{$width}px;
      top: 0;
      bottom: 0;
      @if $side == inside {
        left: 0;
      } @else {
        left: -#{$width}px;
      }
    }

    @if $location == top {
      @if $side == inside {
        background-image: url($arrow);
      } @else {
        @if $type == fill {
          background-image: url($arrow);
        } @else {
          background-image: url($wave);
        }
      }
    } @else {
      @if $side == inside {
        background-image: url($arrow);
      } @else {
        @if $type == fill {
          background-image: url($arrow);
        } @else {
          background-image: url($wave);
        }
      }
    }
  }

  @keyframes slide-hoz {
    from {
      width: 0;
    }
    to {
      width: 100%;
    }
  }

  @keyframes slide-vet {
    from {
      height: 0;
    }
    to {
      height: 100%;
    }
  }
}

/* ----------------------------------------------
 * Generated by Animista on 2020-12-9 0:6:27
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation jello-horizontal
 * ----------------------------------------
 */
@-webkit-keyframes jello-horizontal {
  0% {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
  30% {
    -webkit-transform: scale3d(1.25, 0.75, 1);
    transform: scale3d(1.25, 0.75, 1);
  }
  40% {
    -webkit-transform: scale3d(0.75, 1.25, 1);
    transform: scale3d(0.75, 1.25, 1);
  }
  50% {
    -webkit-transform: scale3d(1.15, 0.85, 1);
    transform: scale3d(1.15, 0.85, 1);
  }
  65% {
    -webkit-transform: scale3d(0.95, 1.05, 1);
    transform: scale3d(0.95, 1.05, 1);
  }
  75% {
    -webkit-transform: scale3d(1.05, 0.95, 1);
    transform: scale3d(1.05, 0.95, 1);
  }
  100% {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}
@keyframes jello-horizontal {
  0% {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1) rotate(2deg) scale(1.021);
  }
  30% {
    -webkit-transform: scale3d(1.25, 0.85, 1);
    transform: scale3d(1.25, 0.85, 1) rotate(2deg) scale(1.021);
  }
  40% {
    -webkit-transform: scale3d(0.75, 1.15, 1);
    transform: scale3d(0.75, 1.15, 1) rotate(2deg) scale(1.021);
  }
  50% {
    -webkit-transform: scale3d(1.15, 0.925, 1);
    transform: scale3d(1.15, 0.925, 1) rotate(2deg) scale(1.021);
  }
  65% {
    -webkit-transform: scale3d(0.95, 1.02, 1);
    transform: scale3d(0.95, 1.02, 1) rotate(2deg) scale(1.021);
  }
  75% {
    -webkit-transform: scale3d(1.05, 0.98, 1);
    transform: scale3d(1.05, 0.98, 1) rotate(2deg) scale(1.021);
  }
  100% {
    transform: scale3d(1, 1, 1) rotate(2deg) scale(1.021);
  }
}

.event-detail-conversion {
  display: flex;
  flex-wrap: wrap;

  @include breakpoint("small-") {
    background-color: #e8e8e8;
    box-shadow: inset 3px 8px 8px rgba(0, 0, 0, 0.15);
    @include jagged(fff, 30, bottom, fill, inside);
  }

  opacity: 0;
  max-height: 0px;
  overflow: hidden;

  &--active {
    padding: 30px 20px 50px;
    opacity: 1;
    max-height: 1000px;

    @include breakpoint("medium+") {
      padding: 30px 10px 20px 0;
      width: 60%;
      margin-left: auto;
    }

    &.kiosk {
      padding-bottom: 0;
    }
  }

  &__arrow-section {
    display: flex;
    flex-wrap: wrap;
    flex: 0 0 40%;

    padding: 0 0 0 10px;

    & > div {
      flex: 0 0 50%;

      &:nth-child(3) {
        border-top: 1px solid $button-border-color;
        border-right: 1px solid $button-border-color;
      }
    }
  }

  &__explanation {
    font-size: 18px;
    color: $color__copy--medium;
    margin-bottom: 20px;
    width: 75%;
    margin-left: auto;
    text-align: right;

    &.kiosk.kiosk--active {
      font-size: 24px;
      color: $white;
      position: absolute;
      left: 4rem;
      text-align: left;
      width: 30%;
    }
  }

  & .ticket-card {
    flex: 0 0 60%;
    margin: 0;

    opacity: 0.6;

    font-style: italic;

    &__thumbnail-wrapper {
      display: none;
    }

    &__event-copy {
      text-align: left;
      width: 100%;
    }

    &:last-child {
      flex: 0 0 70%;

      margin-top: 10px;
      margin-left: auto;

      opacity: 1;
      font-style: normal;
      animation: jello-horizontal 0.9s both;

      box-shadow: -1px 3px 3px rgba(0, 0, 0, 0.15);
    }
  }
}
