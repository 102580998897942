@import "../variables.scss";

$menu-left: 30px;

.menu-hamburger-wrapper {
  position: relative;
}

.menu-hamburger {
  color: $white;
  background-color: transparent;
  position: relative;
  border: none;
  margin: 2px 10px 0;

  position: relative;

  z-index: #{map-get($z-indexes, "banner-text")};

  @include breakpoint("large-") {
    margin-top: 0;
  }

  svg {
    height: 26px;
    color: $white;

    &[aria-current] {
      color: $color__red--barnes;
      border-bottom-color: currentColor;
    }

    &:focus:not(.focus-ring) {
      border-bottom-color: currentColor;
    }

    &:hover {
      color: $color__red--barnes;
      cursor: pointer;
    }

    &:focus:hover {
      color: currentColor;
    }
  }

  &__click-me {
    font-size: 18px;

    position: absolute;
    top: 2px;
    left: -130px;
    width: 120px;
    min-height: 50px;

    display: flex;
    justify-content: center;
    align-items: center;

    background-color: $black;
    color: $white;

    padding: 15px;
    border-radius: 5px;

    transition: opacity 1s;
    opacity: 1;

    z-index: #{map-get($z-indexes, "banner-overlay")};
  }

  &__click-me-arrow {
    color: $black;

    height: 50px;
    width: 50px;

    position: absolute;
    top: 0px;
    right: -30px;
  }
}

.menu-overlay {
  z-index: map-get($z-indexes, "menu-overlay");

  width: 100vw;
  height: 2000vh;

  position: absolute;
  top: 0;
  left: 0;

  background-color: #000;
  opacity: 0;

  transition: opacity 0.5s;

  &--active {
    opacity: 0.3;
  }

  &--hidden {
    height: 0;
    width: 0;
  }
}

.menu {
  z-index: map-get($z-indexes, "menu");

  color: #fff;
  font-family: Calibre, sans-serif;

  overflow: hidden;
  height: 100%;
  width: calc(100% - 70px);
  max-width: 390px;

  position: fixed;
  right: 0;
  top: 0;

  transition: transform 0.5s;
  transform: translate3d(100%, 0, 0);

  &--active {
    transform: translate3d(0%, 0, 0);
  }

  &__inner {
    background-color: $color__red--barnes;
    height: 100%;
    width: 100%;
    overflow: auto;
    padding: 28px 0px;
    display: flex;
    flex-direction: column;
  }

  &__content {
    flex: 1 0 auto;
    width: 100%;
  }

  &__top-content {
    * > {
      display: inline-block;
    }
    width: 100%;
  }

  &__bottom-content {
    flex-shrink: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    width: 100%;
  }

  &__close {
    background-color: transparent;
    border: none;

    position: absolute;
    top: 28px;
    left: $menu-left;
    padding: 0;

    svg {
      color: $white;
      width: 30px;
      height: 22px;
    }
  }

  &__links {
    margin-top: 35px;
    padding: 15px 0 10px;
  }

  &__bottom-line {
    width: 100%;
    border-top: 1px solid $white;
    padding-top: 10px;
  }

  &__item {
    position: relative;
    cursor: pointer;
    transition: all 0.5s;
    color: $white;
    text-decoration: none;
    border: none;
    font-size: 30px;
    line-height: 48px;

    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-weight: 200;

    padding: 0 $menu-left;

    svg {
      height: 28px;
      width: 30px;
      margin-right: 12px;

      fill: $white;
      transition: fill 0.25s;
    }

    &--bottom {
      font-size: 22px;
      font-weight: 200;
      line-height: 45px;
      width: 100%;

      svg {
        width: 19px;
        height: 19px;
        margin-right: 9px;
      }
    }

    &--active {
      text-decoration: underline;
    }

    &--log-out {
      // position: absolute;
      // bottom: 20px;
      // right: 20px;
      line-height: initial;

      font-family: Calibre, sans-serif;
      background-color: transparent;
    }

    &:hover,
    &:active {
      color: $color__nav--hover;

      svg {
        fill: $color__nav--hover;
      }
    }
  }
}

.log-out {
  &__icon {
    display: inline-block;
    margin-right: 5px;
  }

  &__text {
    display: inline-block;
    margin-bottom: 2px;
  }
}
