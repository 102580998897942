@import "../variables.scss";
@import "../mixins.scss";

@mixin day-label($content) {
  &::before {
    content: $content;
    position: absolute;
    top: 2px;
    left: 0;
    width: 100%;
    text-align: center;
    font-size: 8px;
  }
}

///////////////////////////////////
///////// Renewal Page ////////////
///////////////////////////////////

.renewal {
  font-family: "Calibre", "Arial", sans-serif;

  @include breakpoint("medium+") {
    width: 700px;
    margin-left: auto;
    margin-right: auto;
  }

  @include breakpoint("large+") {
    width: 990px;
  }

  &__header {
    font-size: 20px;

    @include breakpoint("medium+") {
      font-size: 27px;
    }
    font-weight: bold;
    line-height: 60px;
    margin: 0px;
    padding: 0 15px 1px 15px;

    background-color: $button-color;
    border: $button-border;

    position: relative;

    &:hover {
      cursor: pointer;
    }

    &--deactivated {
      opacity: 0.3;
    }
  }

  &__header-arrow {
    position: absolute;
    right: 25px;

    height: 13.5px;

    top: calc(50% - 7px);
    transform: rotate(270deg);

    &--active {
      top: calc(50% - 5px);
      transform: rotate(90deg);
    }
  }

  &__item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-height: 0px;
    overflow: hidden;
    transition: opacity 1s, max-height 0.5s;
    width: 100%;

    &--active {
      max-height: 3000px;
    }

    &--deactivated {
      transform: scaleY(0.8);
      max-height: 0;
      opacity: 0.3;
    }

    &--no-height {
      transform: scaleY(1);
      max-height: 3000px;
    }

    &--xl {
      padding: 0;
      max-height: 9999px;
    }

    &--hidden {
      max-height: 0;
      opacity: 0;
    }

    &--continue {
      margin: auto;
    }
  }

  &__summary {
    p {
      margin: 2px;
      font-size: 16px;
    }
    padding: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    &-wrapper {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
    }
    &-content {
      width: 100%;
      display: flex;
      flex-direction: column;
      max-width: 400px;

      @include breakpoint("medium+") {
        flex-direction: row;
        max-width: 700px;
      }

      .column-1 {
        width: 100%;
        @include breakpoint("medium+") {
          width: 50%;
        }
      }
      .column-2 {
        width: 100%;
        @include breakpoint("medium+") {
          width: 50%;
        }
      }
    }
    .offer-subtotal {
      text-align: right;
    }
  }

  &__selection-text {
    @include zeta();

    display: block;
    position: absolute;
    bottom: 1px;
    left: 15px;
  }

  ///////////////////////////////////
  // Styling for react-day-picker. //
  ///////////////////////////////////

  .DayPicker {
    width: 100%;
  }

  .DayPicker-Caption {
    text-align: center;

    line-height: 1.42857143;
  }

  .DayPicker-Month {
    width: 100%;
    border-collapse: separate;
    border-spacing: 3px 3px;

    margin-left: 0;
    margin-right: 0;
    margin-top: 10px;
  }

  .DayPicker-NavButton {
    top: 12px;
    right: 0px;
  }

  .DayPicker-Day {
    background-color: $button-color;
    border: $button-border;
    padding: 10px 0;
    color: #000;
    border-radius: 0;
    font-size: 171%;
    margin: 5px;

    line-height: 1.42857143;

    position: relative;

    &--disabled {
      opacity: 0.5;

      @include day-label("No Events");
    }

    &--today {
      font-weight: initial;

      @include day-label("Today");
    }

    &--outside {
      background-color: transparent !important;
      border: none !important;

      &::before {
        display: none;
      }
    }

    &--selected {
      border-color: $color__red--barnes;
      background-color: $color__red--barnes !important;
    }
  }
}

///////////////////////////////////
//////// Form Styling //////////
///////////////////////////////////

.form {
  $self: &;
  padding: 12px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @include breakpoint("medium+") {
    width: 600px;
    padding: 24px;
    margin-left: auto;
    margin-right: auto;
  }

  @include breakpoint("large+") {
    width: 890px;
  }

  transition: opacity 1s, max-height 0.2s ease;
  max-height: 2000px;
  overflow: hidden;

  &__section {
    &:not(:first-of-type) {
      border-top: 1px dashed $button-border-color;
      margin-top: 25px;
      padding-top: 10px;
    }
  }

  &__input-wrapper {
    width: 100%;
    display: inline-block;
    position: relative;
  }

  &__input {
    -webkit-appearance: none !important;
    -moz-appearance: none !important;
    appearance: none !important;

    display: inline-block;
    width: 100%;

    font-family: Calibre, sans-serif;
    font-size: 22px;
    height: 45px;
    padding: 0 15px;

    border-radius: 0 !important;
    border: $button-border;
    color: $darkestgrey;

    &::placeholder {
      color: $button-border-color;
    }

    &:focus {
      color: $darkestgrey;
    }

    &--error {
      border: 1px solid red;
    }

    &--message {
      color: red;
      font-weight: bold;
      height: 18px;
      margin-bottom: 3px;
    }
  }
}

.autopopulate-checkbox {
  margin-bottom: 16px;
}

.First.Name,
.Last.Name,
.State,
.Zip.Code {
  width: 48%;
  display: inline-block;
}

.First.Name,
.State {
  margin-right: 4%;
}

.add-cardholder-text {
  font-family: Calibre, sans-serif;
  font-size: 16px;
  margin: 8px;
  text-decoration: none;
  background-color: transparent;
  border: none;
  &:hover,
  &:active {
    cursor: pointer;
    text-decoration: underline;
  }

  &:focus:not(.focus-ring) {
    outline: none;
    text-decoration: underline;
  }
}

.member-info {
  width: 100%;
  h2 {
    margin: 0;
  }
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  &__primary-cardholder,
  &__secondary-cardholder {
    margin: 8px;
    padding: 8px;
    width: 100%;
  }
}

///////////////////////////////////
///////// Core Benefits ///////////
///////////////////////////////////

.core-benefits {
  transition: transform 1s;
  background-color: $button-color;
  border: $button-border;
  column-count: 1;

  margin: 0px 13px;
  padding: 28px 16px;
  font-size: 16px;

  @include breakpoint("medium+") {
    column-count: 2;
  }

  ul {
    margin: 0;
    list-style: square;
  }
}

.core-benefits__container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

///////////////////////////////////
//// Styling for coupon code //////
///////////////////////////////////

.coupon-code {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  &__container {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    width: 100%;
    max-width: 400px;
  }

  &__label {
    font-family: Calibre, sans-serif;
    font-weight: 700;
    font-size: 19px;
    color: $color__copy--medium;
    margin-bottom: 1rem;
  }

  &__button {
    border: none;
    height: 45px;
    margin: 0;
    text-decoration: none;
    padding: 1rem 2rem;
    background: $color__red--barnes;
    color: $white;
    font-weight: 700;
    font-size: 1rem;
    font-family: "Calibre";
    line-height: 1;
    cursor: pointer;
    text-align: center;
    -webkit-appearance: none;
    -moz-appearance: none;
  }
}

.error-message {
  @include zeta();
  text-align: center;
  height: 20px;
  font-weight: 700;
  color: $orange;
  margin-bottom: 12px;
}

.submit-button {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

///////////////////////////////////
//////// Summary Section //////////
///////////////////////////////////

.summary-total {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 400px;

  @include breakpoint("medium+") {
    max-width: 700px;
  }

  .total-to-renew {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    text-align: right;
  }

  ///////////////////////////////////
  //// Styling for donation form ////
  ///////////////////////////////////

  .donation-form {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;

    .donation-options {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      width: 100%;
      max-width: 600px;
      text-align: left;

      #other-amount {
        margin: 6px;
        font-size: 20px;
        height: 30px;
        max-width: 200px;
        @include breakpoint("medium+") {
          font-size: 22px;
          height: 40px;
        }
      }

      .radio-checkbox {
        input[type="radio"] {
          opacity: 0;
          position: fixed;
          width: 0;
        }

        label {
          display: flex;
          flex-wrap: wrap;
          justify-content: center;
          align-items: center;
          margin: 6px;
          font-size: 20px;
          height: 30px;
          width: 60px;
          -webkit-user-select: none;
          -moz-user-select: none;
          -ms-user-select: none;
          user-select: none;
          background-color: none;
          border: 1px solid $white;

          cursor: pointer;
          @include breakpoint("medium+") {
            font-size: 22px;
            height: 40px;
            width: 80px;
          }
        }

        label:hover {
          background-color: $button-color;
          color: $color__red--barnes-dark;
        }

        input[type="radio"]:checked + label {
          background-color: $button-color;
          color: $color__red--barnes-dark;
        }
      }
    }

    .value-proposition {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 16px 24px;

      &-wrapper {
        width: 100%;
      }

      &__content {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: center;
        max-height: 275px;
        transition: max-height 0.5s;
        width: 100%;
      }

      &__content-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        max-height: 275px;
        transition: max-height 0.5s;
        width: 100%;
      }

      &__content-wrapper--active {
        max-height: 275px;
        transition: max-height 0.5s;
      }

      &__header {
        font-weight: 600;
        font-size: 24px !important;
        margin-bottom: 6px;

        &--thanks {
          font-weight: 700;
          font-size: 36px !important;
          margin: 0;
        }
      }

      &__subheader {
        font-weight: 400;
        font-size: 16px !important;

        &--thanks {
          font-weight: 700;
          font-size: 20px;
          margin: 0;
        }
      }

      &__button-wrapper {
        z-index: 10;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      &__button {
        font-size: 18px;
        width: 200px;
      }
    }
  }
}
