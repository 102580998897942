@import "../variables.scss";

$splash-z-indexes: (
  background: -1,
  overlay: 0,
  content: 3,
);

.splash {
  z-index: map-get($splash-z-indexes, "content");

  text-align: center;
  color: $white;

  font-family: Calibre, sans-serif;
  animation: slide-up 0.4s ease;

  width: 100%;

  padding: 0 30px;

  @include breakpoint("medium+") {
    padding: 0;
    width: 50%;
    max-width: 600px;
  }

  &__overlay {
    z-index: map-get($splash-z-indexes, "overlay");

    position: absolute;
    top: 0;
    left: 0;

    background-color: #000;
    opacity: 0.4;

    @include breakpoint("medium+") {
      opacity: 0.3;
    }

    height: 100%;
    width: 100%;
  }

  &__background {
    z-index: map-get($splash-z-indexes, "background");

    position: absolute;
    top: 0;
    left: 0;

    height: 100%;

    @include breakpoint("medium+") {
      height: auto;
      width: 100%;
    }
  }

  &__login-flex {
    .btn-barnes {
      max-width: none;
    }
    z-index: map-get($splash-z-indexes, "content");

    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;

    &--start {
      align-items: flex-start;
    }

    &--end {
      align-items: flex-end;
    }
  }

  &__header {
    font-size: 32px;
    @include breakpoint("medium+") {
      font-size: 36px;
    }
    margin: 32px 0 0 0;
  }

  &__login-cont {
    width: 100%;

    margin-top: 20px;

    display: flex;
    flex-direction: column;

    justify-items: center;

    @include breakpoint("medium+") {
      width: 60%;
      max-width: 550px;
    }

    @include breakpoint("large+") {
      max-width: 550px;
    }

    & > * {
      animation: slide-up 0.4s ease;
    }

    .login-form__input {
      -webkit-appearance: none !important;
      -moz-appearance: none !important;
      appearance: none !important;
      z-index: 1;
      display: inline-block;
      width: 100%;

      font-family: Calibre, sans-serif;
      font-size: 22px;
      height: 60px;

      padding: 0 15px;
      margin: 5px 0;

      border-radius: 0 !important;
      border: 2px solid $button-border-color;
      color: $darkestgrey;

      @include breakpoint("medium+") {
        padding: 0 22px;
      }

      &::placeholder {
        color: $button-border-color;
      }

      &:focus {
        color: $darkestgrey;
      }
    }

    .login-btn {
      font-size: 22px;

      border: 1px solid $button-border-color;

      margin-top: 0;

      color: $orange;
      background-color: #ffffff;
      font-weight: bold;
    }
  }

  &__spinner {
    margin: 0 !important;

    div {
      background-color: #fff !important;
    }
  }

  &__logo {
    max-width: 230px;
    margin-bottom: 70px;

    @include breakpoint("small+") {
      max-width: 325px;
      margin-bottom: 100px;
    }

    &.logo-100 {
      margin-bottom: 50px;

      @include breakpoint("small+") {
        margin-bottom: 70px;
      }
    }
  }

  @keyframes slide-up {
    0% {
      opacity: 0;
      transform: translateY(20px);
    }

    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
}

.login-form__field input {
  width: 100%;
  box-sizing: border-box;
  border-radius: 2px;
  z-index: 0;
  position: relative;
}

.login-form__label {
  color: $button-border-color;
  position: absolute;
  transition: 0.2s all;
  z-index: 2;
  width: 100%;
  height: 60px;
  font-size: 22px;
  padding: 0 15px;
  margin: 22px 0;
  pointer-events: none;
}

.transform-label {
  transform: translateY(-10px);
  font-size: 12px;
}
