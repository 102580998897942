.user-card {
  $self: &;
  // margin: 26px 0 0;
  padding-bottom: 134px;

  min-height: 73vh;
  position: relative;

  &__qr-image {
    width: 189px;
  }

  &--shorter {
    margin-top: 20px;
    padding: 0; //20px 20px 20px;
    border: none;
    min-height: calc(73vh * 0.66);
  }

  &--no-height {
    padding: 0; //20px 20px 20px;
    border: none;
    min-height: 0;
  }

  &--no-bottom {
    padding-bottom: 0;
  }

  &--guest-passes {
    margin-top: 20px;
    padding: 0; //20px 20px 20px;
    border: none;
    min-height: 0;
  }

  &--item {
    border: $grey solid 2px;
    text-decoration: none;
    color: $black;
    min-height: 100%;
    height: 3em;
    padding-bottom: 10em;
    display: grid;
    grid-template-columns: 4fr 1fr;
  }

  &--border {
    padding: 0 20px 20px 20px;
    margin-top: 20px;
    border: $grey solid 2px;
  }

  &--details {
    padding-top: 10px;
    display: flex;
    flex-flow: row nowarp;
    justify-content: space-between;
    align-items: flex-start;
  }

  &--row {
    width: 48%;
  }

  &__link {
    text-decoration: none;
  }

  .user-card--group {
    margin-bottom: 30px;

    &__qr {
      margin-bottom: 0;
      position: absolute;
      bottom: 10px;
      left: 50%;
      transform: translate(-50%);
    }
  }

  .user-card--title {
    margin: 0 0 5px;
    font-weight: bold;
    color: $orange;
  }

  .user-card--event-name {
    font-weight: bold;
  }

  .user-card--contents {
    margin: 0 0 5px;
    font-weight: 400;
    color: #707070;
    font-size: 18px;
  }
}
