@import "../variables.scss";

html,
body,
#root {
  height: 100%;
}

.app {
  height: 100%;
  display: flex;
  flex-direction: column;

  &__content {
    z-index: map-get($z-indexes, "content");
    flex: 1 0 auto;
    display: flex;
    flex-direction: column;

    &--center-splash {
      height: 100%;
      padding: 16px;
      align-items: center;
      justify-content: center;

      max-height: 100vh;
      max-width: 100vw;
      overflow: hidden;
      position: relative;
    }
  }

  &__footer {
    flex-shrink: 0;
    z-index: map-get($z-indexes, "footer");
  }
}
