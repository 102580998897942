@import "../variables.scss";
@import "../mixins.scss";

@mixin day-label($content) {
  &::before {
    content: $content;
    position: absolute;
    top: 2px;
    left: 0;
    width: 100%;
    text-align: center;
    font-size: 8px;
  }
}

.event-detail {
  width: 100%;
  padding: 12px 8px 0px 8px;
  @include breakpoint("medium+") {
    width: 650px;
    padding: 24px 16px 0px 16px;
    margin-left: auto;
    margin-right: auto;
  }

  @include breakpoint("large+") {
    width: 990px;
  }

  &__header {
    width: 100%;
    display: flex;
    font-family: Calibre, sans-serif;
    align-items: center;
    justify-content: flex-start;
    font-size: 27px;
    font-weight: bold;
    margin-top: 10px;
    padding: 0 15px 10px 15px;
    line-height: 60px;

    background-color: $button-color;
    border: $button-border;

    position: relative;

    &:hover {
      cursor: pointer;
    }

    &--deactivated {
      opacity: 0.3;

      &:last-of-type {
        margin-bottom: 20px;
      }
    }
  }

  &__header-arrow {
    position: absolute;
    right: 25px;

    height: 13.5px;

    top: calc(50% - 7px);
    transform: rotate(270deg);

    &--active {
      top: calc(50% - 5px);
      transform: rotate(90deg);
    }
  }

  &__item {
    max-height: 0px;
    overflow: hidden;
    padding: 0 20px;

    transition: opacity 1s, max-height 0.5s;

    &--active {
      // why media only rather than breakpoint? the xsmall+ breakpoint logic doesn't seem to want to work
      // because of the null starting point and I don't want to break the whole site.
      @media only screen and (min-width: 1px) {
        max-height: 3500px;
      }
      @include breakpoint("medium+") {
        max-height: 3000px;
      }
      &.kiosk {
        overflow-y: auto;
      }
    }

    &--deactivated {
      transform: scaleY(0.8);
      max-height: 0;
      opacity: 0.3;
    }

    &--no-height {
      transform: scaleY(1);
      max-height: 3000px;
    }

    &--xl {
      padding: 0;
      max-height: 9999px;
    }

    &--hidden {
      max-height: 0;
      opacity: 0;
    }
  }

  &__selection-text {
    @include zeta();
    display: block;
    position: absolute;
    bottom: 5px;
    left: 15px;
  }

  ///////////////////////////////////
  // Styling for react-day-picker. //
  ///////////////////////////////////
  .DayPicker {
    width: 100%;
  }

  .DayPicker-Caption {
    text-align: center;

    line-height: 1.42857143;
  }

  .DayPicker-WeekdaysRow {
    // visibility: hidden;
    // display: none;
  }

  .DayPicker-Month {
    width: 100%;
    border-collapse: separate;
    border-spacing: 3px 3px;

    margin-left: 0;
    margin-right: 0;
    margin-top: 10px;
  }

  .DayPicker-NavButton {
    top: 12px;
    right: 0px;
  }

  .DayPicker-Day {
    background-color: $button-color;
    border: $button-border;
    padding: 10px 0;
    color: #000;
    border-radius: 0;
    font-size: 171%;
    margin: 5px;

    line-height: 1.42857143;

    position: relative;

    &--disabled {
      opacity: 0.5;

      @include day-label("No Events");
    }

    &--today {
      font-weight: initial;

      @include day-label("Today");
    }

    &--outside {
      background-color: transparent !important;
      border: none !important;

      &::before {
        display: none;
      }
    }

    &--selected {
      border-color: $color__red--barnes;
      background-color: $color__red--barnes !important;
    }
  }
}
