.expired {
    text-align: center;
    background: white;
    width: 80%;
    margin-left: 50%;
    transform: translate(-50%);
    padding: 20px;
    padding-bottom: 70px;
    border-radius: 20px;
    border: solid 1px #ccc;
    margin-top: 30%;

    @media only screen and (min-width: 600px) {
        width: 360px;
    }

    &:focus {
        outline: none;
    }

    &-btn {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        border-top: solid 1px #ccc;
        padding-top: 22px;
        padding-bottom: 21px;
        cursor: pointer;
        text-decoration: none;
        font-weight: bold;
        color: $light-blue;
    }

    &-close {
        position: absolute;
        top: 14px;
        right: 16px;
        cursor: pointer;
        height: 20px; 
    }
}