.banner-card {
  $self: &;

  text-decoration: none;
  color: $black;

  &:last-of-type {
    #{ $self } {
      &__text {
        border-bottom: none;
        padding: 1em 0 0 0;
      }
    }
  }

  &__text-wrapper {
    font-family: Calibre, sans-serif;
    font-size: 18px;
    text-decoration: none;

    padding: 0 20px 0px 20px;
    margin-bottom: 20px;
  }

  &__text {
    p {
      &:first-of-type {
        margin-top: 0 !important;
      }

      &:last-of-type {
        margin-bottom: 0 !important;
      }
    }

    // Override any child font-family styling.
    & * {
      line-height: inherit !important;
      text-decoration: none !important;
      font-style: normal !important;
      font-size: inherit !important;
      font-family: inherit !important;
    }

    padding: 1em 0;
    border-bottom: 1px solid $grey;
  }
}
