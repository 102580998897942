.loader-container {
    margin-top: 50px;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 50%;
    transform: translate(-50%);

  }
  
  .loader-text {
    margin: 0 0 0 10px
  }
  
  .loader {
    border: 5px solid #f3f3f3; /* Light grey */
    border-top: 5px solid $orange;
    border-radius: 50%;
    width: 22px;
    height: 22px;
    animation: spin 2s linear infinite;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }