@import "../variables.scss";
@import "../mixins.scss";

.kiosk-container {
  display: flex;
  flex-direction: column;
  padding: 24px 36px;

  .splash__background {
    height: 100%;
    object-fit: cover;
  }

  .event-detail {
    font-family: "Calibre", "Arial", sans-serif;
    width: 100%;
    padding: 12px 8px 0px 8px;
    margin-top: 120px;
  }
}

.print {
  display: flex;
  flex-direction: column;
  text-align: center;
  font-weight: 500;
  font-size: 18px;
  width: 264px; // 2.75 inches
  border-collapse: collapse;
  padding: 0 0 10px 35px;
  font-weight: 100;
  font-size: 12px;
  line-height: 14px;

  &__header {
    margin-bottom: 20px;

    h1 {
      font-weight: 100;
      font-size: 22px;
      margin: 15px 0 10px;
      line-height: 24px;
    }

    h2 {
      font-weight: 100;
      font-size: 12px;
      line-height: 14px;
      margin: 0;
    }
  }

  &__footer {
    font-size: 12px;
    margin-top: 10px;
  }

  &__qr {
    width: 100%;
  }
}

@media print {
  .pagebreak {
    clear: both;
    page-break-after: always;
  }
}

.kiosk__disclaimer {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0 20px;
  margin-left: auto;
  margin-right: auto;
  width: 100%;

  h4 {
    @include zeta();
    margin: 10px !important;
    color: $white !important;
  }
}

.kiosk-modal {
  background: rgba(0, 0, 0, 0.6);
  backdrop-filter: blur(3px);
  height: 100%;
  width: 100%;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  z-index: #{map-get($z-indexes, "modal")};

  &__content {
    background: $white;
    padding: 20px 24px;
    margin: auto;
    width: 50%;
  }

  &__buttons {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }

  &__back {
    margin: auto;
    display: block;
    background: none;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
  }
}

.kiosk-order-confirmation {
  display: flex;
  align-items: center;
  justify-content: center;

  &__container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    margin: 20px;
    position: relative;
    line-height: 2;
    width: 100%;
    font-size: 110%;
    background-color: #f7f7f7;
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
  }

  &__header-wrapper {
    width: 100%;
    display: flex;
    align-items: center;
    background-color: $black;
    color: $white;
    height: 50px;
  }

  &__header {
    padding: 10px 20px;
    margin: 0;
    width: 100%;
    font-size: 22px;
    font-weight: 200;
  }

  &__copy {
    margin: 0;
    max-width: 500px;
    font-size: 22px;
    font-weight: 200;
    text-align: center;
  }

  &__footer {
    max-width: 800px;
    margin-bottom: 12px;

    p {
      text-align: left;
      @include zeta();
    }
  }

  &__qr {
    width: 200px;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      width: 100%;
    }
  }

  &__button {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 12px;
  }
}

.kiosk-ticket-details {
  margin-bottom: 12px;
  margin-top: 12px;
  display: flex;
  align-items: center;
  justify-content: center;

  &__header,
  &__date,
  &__item,
  &__detail {
    margin: 0;
    padding: 0;
    line-height: 36px;
  }

  &__content {
    margin-bottom: 12px;
    margin-top: 12px;
  }
}

.receipt {
  text-align: center;
  width: 100%;

  &__section {
    align-items: left;
    width: 100%;

    &:last-of-type {
      margin-bottom: 40px;
    }

    &__header {
      overflow: hidden;
      text-overflow: clip;
      white-space: nowrap;
      max-width: 264px;
    }

    &__content {
      margin: 10px;
      width: 100%;

      &--row {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        &.center {
          justify-content: center;
        }
      }
    }
  }
}

.kiosk.input-form {
  .checkbox-container {
    margin-right: auto;
  }
}

.kiosk-buttons {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 890px;
  margin: auto;

  .nav-buttons {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .btn-barnes-secondary {
    background-color: $white;
  }

  .btn-plain {
    background: none !important;
    border: none;
    padding: 0 !important;
    color: $white;
    text-decoration: underline;
    cursor: pointer;
  }

  #btn-reset {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: fixed;
    bottom: 1rem;
    left: 50%;
    transform: translate(-50%, 0);
    width: 890px;
  }
}

.kiosk__value-proposition {
  .value-proposition__button--continue {
    margin: auto;
    font-family: Calibre, sans-serif;
    font-size: 18px;
    font-weight: 600;
    height: 50px;
    line-height: 45px;
    color: #fff;
    background-color: transparent;
    border: none;
    cursor: pointer;
    text-decoration: underline;
  }
}

.kiosk__keyboard {
  background-color: $color__grey--light;
  border-radius: 5px;

  .hg-theme-default {
    background-color: $color__grey--light;

    .hg-button {
      height: 60px;
    }
  }

  .hg-rows {
    font-family: Calibre, sans-serif;
    font-size: 22px;
    background-color: $color__grey--light;
    color: $darkestgrey;
  }
}