@import "../variables.scss";

.hero-banner {
  $self: &;

  position: relative;
  z-index: #{map-get($z-indexes, "banner-text")};

  &--no-image {
    #{ $self } {
      &__header {
        color: $black;
      }

      &__image-wrapper {
        background-color: transparent;
      }

      &__image-overlay {
        opacity: 0 !important;
      }
    }
  }

  &__header-wrapper {
    text-align: center;
    position: absolute;

    display: flex;
    justify-content: center;
    align-items: flex-end;
    @include breakpoint("medium+") {
      align-items: center;
    }

    width: 100%;
    height: 100%;

    z-index: map-get($z-indexes, "banner-text");

    padding: 0 20px;
  }

  &__spinner {
    margin: 0;
  }

  &__header {
    font-weight: 700;
    font-size: 36px;
    @include breakpoint("medium+") {
      font-size: 70px;
    }

    line-height: 1;
    color: $white;

    width: 100%;

    transition: opacity 0.3s;
  }

  &__image-wrapper {
    overflow: hidden;
    max-height: 200px;
    @include breakpoint("small+") {
      max-height: 225px;
    }
    @include breakpoint("medium+") {
      max-height: 250px;
    }
    @include breakpoint("large+") {
      max-height: 300px;
    }
    @include breakpoint("xlarge+") {
      max-height: 350px;
    }
    z-index: map-get($z-indexes, "banner-image");

    background-color: $color__grey--light;

    transition: background-color 1s;

    position: relative;
  }

  &__image {
    width: 100%;

    transition: opacity 1s;
  }

  &__image-overlay {
    position: absolute;
    top: 0;

    width: 100%;
    height: 100%;

    background-color: #000;
    opacity: 0;

    transition: opacity 1s;

    &--loaded {
      opacity: 0.2;
    }

    &:after {
      content: " ";
    }
  }
}
