.general {
	margin-bottom: 25px;

	&__error {
		margin-top: 20px;
		width: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
		text-align: center;
	}

	&__sad-face {
		width: 140px;
		display: block;
	}

	&__error-text {
		margin-top: 30px;
		font-size: 18px;
		color: $color__copy--medium;
	}
}