@import "../variables.scss";
@import "../mixins.scss";

$value-proposition-z-indexes: (
  content: 3,
  overlay: 2,
  image: 1,
);

.value-proposition-wrapper {
  position: relative;
  overflow: hidden;

  transition: opacity 0.3s, max-height 0.3s;

  margin-top: 10px;

  @include breakpoint("medium+") {
    margin-top: 30px;
  }
}

.value-proposition {
  a {
    color: $white;
  }

  $self: &;

  color: $white;
  background-color: $color__red--barnes;

  font-weight: 500;

  padding: 10px 20px;

  position: relative;

  z-index: map-get($value-proposition-z-indexes, "content");

  &--clear {
    background-color: transparent; // $color__red--barnes-dark;
  }

  &__overlay {
    position: absolute;
    top: 0;

    width: 100%;
    height: 100%;

    z-index: map-get($value-proposition-z-indexes, "overlay");

    background-color: $color__red--barnes-dark;
    opacity: 1;
    transition: opacity 0.4s;

    &--active {
      opacity: 0;
    }
  }

  &__header {
    font-weight: 600;
    font-size: 27px;

    @include breakpoint("medium+") {
      font-weight: 700;
      font-size: 45px;
      text-align: center;
    }

    &--thanks {
      font-weight: 700;
      font-size: 45px;
      margin: 0;
    }
  }

  &__pitch {
    min-height: 120px;
    line-height: 1.25;
    margin-bottom: 0;

    @include breakpoint("medium+") {
      width: 60%;
      margin: 30px auto 0;
    }

    &--center {
      text-align: center;
      width: 80%;
    }

    ul {
      list-style: square;
    }
  }

  &__button-wrapper {
    display: flex;
    flex-direction: column;

    &--center {
      align-items: center;
      margin-bottom: 20px;

      & > label {
        margin: 0 0 0 10px;
        font-weight: bold;
        font-size: 20px;
        text-decoration: underline;
      }
    }
  }

  &__button {
    border: 1px solid $white;

    height: 40px;
    line-height: 40px;

    cursor: pointer;

    @include breakpoint("medium+") {
      max-width: 220px;
    }

    &:first-child {
      margin-right: 15px;
    }

    &--affirmative {
      background-color: white;
      color: $color__red--barnes;

      &:hover,
      &:active {
        background-color: white;
        border: 1px solid $white;
      }
    }

    &--deny {
      font-weight: 300;
    }

    &--upsell {
      margin: auto;

      font-family: Calibre, sans-serif;
      font-size: 22px;
      font-weight: 600;
      height: 50px;
      line-height: 45px;

      background-color: #fff;
      color: $color__red--barnes-dark;
      border: 1px solid #fff;

      transition: background-color 0.4s, color 0.4s;

      &:first-child {
        margin: 20px auto 10px;
      }
    }

    &--active {
      color: #fff;
      background-color: transparent;
    }
  }

  &__checkbox {
    position: relative;
    -webkit-appearance: none;
    appearance: none;
    background: transparent;
    border: 2px solid $white;
    width: 35px;
    height: 35px;
    border-radius: 0;
    margin: 0;

    overflow: hidden;

    transition: border-color 0.2s;

    &:checked {
      border-color: $white;

      &::after {
        content: "X";
        color: $white;
        font-size: 55px;

        position: absolute;
        left: 50%;
        top: 55%;
        -webkit-transform: translate(-50%, -50%);
        -moz-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
      }
    }
  }

  &__disclaimer {
    @include zeta();

    margin: 0;
    color: $white;

    & > a {
      color: $white;
    }
  }

  &__close {
    // background-color: transparent;
    // border: none;

    // position: absolute;
    // top: 20px;
    // right: 20px;

    // svg {
    //     color: $white;
    //     width: 22px;
    //     height: 22px;
    // }
    display: none;
  }

  &__clearfix {
    min-height: 20px;
    margin-bottom: 10px;

    &:after {
      content: " ";
      display: block;
    }
  }

  &__next {
    @include zeta();

    background: transparent;
    border: none;
    color: $white;
    text-align: left;
    text-decoration: underline;
    padding: 0;
    margin: 0;
  }

  &__user-icon {
    height: 28px;
    fill: $white;
  }

  &__toggle-wrapper {
    opacity: 0;

    transition: opacity 0.5s;

    display: flex;
    justify-content: center;
    align-items: center;

    @include breakpoint("medium+") {
      font-size: 22px;
    }

    &--active {
      opacity: 1;
    }
  }

  &__glitter-wrapper {
    position: absolute;
    top: 0;
    left: 0;

    z-index: map-get($value-proposition-z-indexes, "image");

    height: 100%;
    width: 100%;

    background-color: $color__red--barnes-dark;
  }

  &__thanks {
    position: absolute;
    top: 0;
    left: 0;

    height: 100%;
    width: 100%;

    transform: translate3d(0, -100%, 0);

    opacity: 0;
    transition: opacity 0.4s, transform 0.2s ease;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  &__content {
    opacity: 1;
    transition: opacity 0.4s;
  }

  &__content-wrapper {
    position: relative;

    &--active {
      #{ $self } {
        &__content {
          opacity: 0;
        }

        &__thanks {
          transform: translate3d(0, 0, 0);
          opacity: 1;
        }
      }
    }
  }
}

@for $i from 0 through 30 {
  $w: random(8);
  $l: random(100);

  $colors: (
    lighten($color__nav--hover, 10%),
    $color__nav--hover,
    $color__red--barnes
  );

  .value-proposition__glitter--#{$i} {
    position: absolute;
    width: #{$w + 28}px;
    height: #{$w + 28}px;
    background-color: nth($colors, random(3));
    top: -10%;
    left: unquote($l + "%");
    opacity: random() * 0.2 + 0.2;
    transform: rotate(#{random() * 360}deg);
    animation: drop-#{$i}
      unquote(4 + random() + "s")
      unquote(random() + "s")
      infinite;
  }

  @keyframes drop-#{$i} {
    100% {
      top: 110%;
      left: unquote($l + random(15) + "%");
    }
  }
}
