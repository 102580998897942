@import "../variables.scss";

.time-select {
  $self: &;

  padding: 0;
  list-style-type: none;
  margin: 15px 0 0 0;

  transition: opacity 1s, max-height 0.5s;

  &__button {
    position: relative;

    line-height: 2;
    width: 100%;
    height: 100%;

    margin-bottom: 10px;

    padding: 10px;
    background-color: $button-color;
    border: $button-border;

    &:first-of-type {
      margin-top: 10px;
    }

    &:hover {
      cursor: pointer;
    }

    &--selected {
      border-color: $color__red--barnes;
      background-color: $color__red--barnes !important;
      button {
        color: $white;
      }
      & #{ $self } {
        &__warn {
          color: $white;
        }
      }
    }

    &--disabled {
      opacity: 0.5;
      font-style: italic;

      &:hover {
        cursor: default;
      }
    }
    button {
      cursor: pointer;
      -webkit-appearance: none;
      -moz-appearance: none;
      width: 100%;
      height: 100%;
      background-color: transparent;
      border: none;
      font-size: 110%;
      font-family: Calibre, sans-serif;
      text-align: left;
      margin: 0;
      padding: 0;
    }
  }

  &__warn {
    @include zeta();
    display: block;
    font-weight: bold;
    font-style: normal;
    position: absolute;
    bottom: 1px;
  }
}
