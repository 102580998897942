@import "../variables.scss";
@import "../mixins.scss";

.slider-card {
  $self: &;

  border: $button-border;
  margin-right: 1px;

  margin-left: 10px;
  margin-right: 10px;

  max-width: calc(100% - 40px);

  display: flex;
  flex-direction: column;

  &:first-of-type {
    margin-left: 20px;
  }

  &:last-of-type {
    margin-right: 20px;
  }

  &__qr-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__qr {
    width: 400px;
    max-width: 80%;

    margin: 25px 0;
  }

  &__information {
    width: 100%;
    padding: 10px 20px;

    background-color: $color__grey--light;

    position: relative;

    flex-grow: 1;
  }

  &__description {
    @include description();

    &--armn {
      color: $color__red--barnes;
      font-size: 22px;
      font-weight: 600;
    }

    &--detail {
      #{ $self } {
        &__description-value {
          white-space: nowrap;
        }
      }
    }

    line-height: 27px;
  }

  &__sub-header {
    margin-bottom: 10px;
  }

  &__description-title {
    font-weight: 700;
  }

  &__armn-logo {
    width: 90px;
    height: 50px;
    position: absolute;

    top: 10px;
    right: 10px;

    path {
      fill: $color__red--barnes;
    }
  }
}

.slider {
  width: 100%;
  overflow-x: scroll;

  &::-webkit-scrollbar {
    width: 0px;
    height: 0px;
    background: transparent;
  }

  -ms-overflow-style: none;

  &__content {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    overflow-x: scroll;
  }

  &__bubbles-wrapper {
    width: 100%;
    margin-top: 30px;

    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__bubbles {
    display: flex;
    justify-content: center;
    padding-bottom: 16px;
  }

  &__bubble {
    border: none;
    padding: 0;

    border-radius: 50%;
    height: 10px;
    width: 10px;

    margin: 0 5px;

    background-color: $button-border-color;
    transition: background-color 0.5s ease;

    &--active {
      background-color: $color__red--barnes;
    }
  }
}