@import "../variables.scss";

$facebook-blue: rgb(59, 89, 152);
$twitter-blue: rgb(0, 172, 237);

.back-to-the-barnes {
  .social-link-text {
    color: $white !important;
    font-size: 18px;
    font-family: Calibre, sans-serif !important;
  }

  &__button-wrapper {
    display: flex;
    flex-direction: column;

    margin-left: auto;
    margin-right: auto;

    padding: 0 22px;
  }

  &__button {
    color: inherit;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    border: none;

    font-weight: 300;

    margin: 5px 0;

    &:last-child {
      margin-bottom: 0;
    }

    &--facebook {
      background-color: $facebook-blue !important;
    }

    &--twitter {
      background-color: $twitter-blue !important;
    }

    &--instagram {
      background: #f09433;
      background: -moz-linear-gradient(
        45deg,
        #f09433 0%,
        #e6683c 25%,
        #dc2743 50%,
        #cc2366 75%,
        #bc1888 100%
      );
      background: -webkit-linear-gradient(
        45deg,
        #f09433 0%,
        #e6683c 25%,
        #dc2743 50%,
        #cc2366 75%,
        #bc1888 100%
      );
      background: linear-gradient(
        45deg,
        #f09433 0%,
        #e6683c 25%,
        #dc2743 50%,
        #cc2366 75%,
        #bc1888 100%
      );
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f09433', endColorstr='#bc1888',GradientType=1 );

      &:hover,
      &:active,
      &:visited {
        color: white !important;
      }
    }
  }

  &__button-icon {
    display: inline-block;
    margin-left: 10px;
    width: 35px;
    height: 35px;

    &--instagram {
      margin-left: 12px;
      fill: white;
      color: white;
      width: 15px;
    }
  }
}
