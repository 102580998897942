@import "./variables.scss";
@import "./mixins.scss";

// Global styling for style atoms.

/////////////
/// BUTTONS ///
/////////////
.btn-barnes {
  width: 100%;
  max-width: 400px;
  line-height: 54px;

  font-family: Calibre, sans-serif;
  font-weight: 700;
  font-size: 19px;

  background-color: $color__red--barnes;
  color: $white;
  border: 2px solid $color__red--barnes;
  border-radius: 0;

  cursor: pointer;
  display: inline-block;
  height: 60px;
  padding: 0 30px;
  text-align: center;
  text-decoration: none;
  transition: all 0.25s ease 0.1s;
  white-space: nowrap;

  margin-top: 10px;
  margin-bottom: 20px;

  &:active,
  &:hover {
    border-color: $color__red--barnes-dark;
    background-color: $color__red--barnes-dark;
  }

  transition: color 0.5s, background-color 0.5s, border-color 0.5s;

  &--disabled {
    color: $color__copy--medium;
    border-color: $grey;
    background-color: $grey;

    &:active,
    &:hover {
      cursor: default;
      color: $color__copy--medium;
      border-color: $grey;
      background-color: $grey;
    }
  }
}

.btn-barnes-secondary {
  width: 100%;
  max-width: 400px;
  line-height: 54px;

  font-family: Calibre, sans-serif;
  font-weight: 700;
  font-size: 19px;

  background-color: transparent;
  border: 2px solid $grey;
  color: $color__copy--medium;
  border-radius: 0;

  cursor: pointer;
  display: inline-block;
  height: 60px;
  padding: 0 30px;
  text-align: center;
  text-decoration: none;
  transition: all 0.25s ease 0.1s;
  white-space: nowrap;

  margin-top: 10px;
  margin-bottom: 20px;

  &:active,
  &:hover {
    border-color: $color__copy--light;
    color: $black;
  }

  transition: color 0.5s, background-color 0.5s, border-color 0.5s;

  &--disabled {
    color: $color__copy--medium;
    border-color: $grey;
    background-color: $grey;

    &:active,
    &:hover {
      cursor: default;
      color: $color__copy--medium;
      border-color: $grey;
      background-color: $grey;
    }
  }
}

/////////////
/// LINKS ///
/////////////
a {
  color: $color__copy--light;
  text-decoration: underline;

  &:hover {
    color: $color__copy--dark;
    cursor: pointer;
  }
}

.a-basic-link {
  color: currentColor;
  text-decoration: none;

  &:hover,
  &:active {
    text-decoration: underline;
  }

  &:focus:not(.focus-ring) {
    outline: none;
    text-decoration: underline;
  }
}

.a-brand-link {
  color: $color__red--barnes;
  text-decoration: none;

  &:visited {
    color: mix(#000, $color__red--barnes, 10%);
  }

  &:hover,
  &:active {
    color: $color__red--barnes-dark;
  }

  &:focus:not(.focus-ring) {
    outline: none;
    text-decoration: underline;
  }

  &--no-underline {
    text-decoration: none !important;

    &:active,
    &:visited {
      text-decoration: none !important;
    }
  }
}

body {
  /* padding: 10px; */
  font-family: Calibre, sans-serif;
}

p {
  font-family: FFMiloSerifWebText, serif;
  font-size: 18px;
  line-height: 1.75rem;
}

* {
  box-sizing: border-box;
}

.btn {
  border: solid 1px $black;
  border-radius: 5px;
  background: $white;
  padding: 13px 40px;
  text-decoration: none;
  color: $black;
  margin-top: 10px;
  display: inline-block;
  font-size: 20px;
  cursor: pointer;
  font-weight: bold;
  width: 100%;

  &:hover {
    background: darken($white, 10%);
  }

  &:disabled {
    background: darken($white, 5%);
  }
}

// Style recaptcha z-index
.grecaptcha-badge {
  z-index: 999;
}

.center {
  text-align: center;
  z-index: 10;
}

.container {
  max-width: 2000px;
  padding: #{map-get($pagePadding, "xsmall")};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @include breakpoint("medium+") {
    padding: #{map-get($pagePadding, "medium")};
    padding-top: #{map-get($pagePadding, "xsmall")};
  }

  @include breakpoint("large+") {
    padding: #{map-get($pagePadding, "large")};
    padding-top: #{map-get($pagePadding, "small")};
  }

  @include breakpoint("xlarge+") {
    padding: #{map-get($pagePadding, "xlarge")};
    padding-top: #{map-get($pagePadding, "medium")};
  }
}
.wrapper {
  margin-top: -#{map-get($headerHeights, "xsmall")}; /* 1 */

  @include breakpoint("medium+") {
    margin-top: -#{map-get($headerHeights, "medium")}; /* 1 */
  }

  @include breakpoint("large+") {
    margin-top: -#{map-get($headerHeights, "large")}; /* 1 */
  }

  @include breakpoint("xlarge+") {
    margin-top: -#{map-get($headerHeights, "xlarge")}; /* 1 */
  }
}

/**
 * Reset
 */
html,
body,
div,
span,
object,
iframe,
blockquote,
pre,
abbr,
address,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
samp,
small,
strong,
sub,
sup,
var,
b,
i,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
dialog,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
time,
mark,
audio,
video {
  background: transparent;
  font-size: 100%;
  vertical-align: baseline;
  outline: 0;
}

article,
aside,
dialog,
figure,
footer,
header,
hgroup,
nav,
section,
main {
  display: block;
}

html {
  overflow-y: scroll;
  overflow: -moz-scrollbars-vertical;
  box-sizing: border-box;
  touch-action: manipulation;
}

blockquote,
q {
  quotes: none;
}

ul,
ol {
  list-style: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

*,
*::before,
*::after {
  box-sizing: inherit;
  touch-action: inherit;
}

.svg-sprite {
  position: absolute;
  z-index: -1;
  left: -1px;
  top: -1px;
  width: 1px;
  height: 1px;
  overflow: hidden;
}

@-ms-viewport {
  width: device-width;
}
