@import "../variables.scss";
@import "../mixins.scss";

.input-form {
  margin-top: 24px;
  @include breakpoint("medium+") {
    width: 600px;
    margin-left: auto;
    margin-right: auto;
  }

  @include breakpoint("large+") {
    width: 890px;
  }

  &.kiosk {
    width: 100%;
    margin-top: 5px;
    padding: 10px 20px 0;
  }

  $self: &;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  transition: opacity 1s, max-height 0.2s ease;
  max-height: 2000px;
  overflow: hidden;

  &--deactivated {
    max-height: 0;
    opacity: 0;
  }

  &__form {
    padding: 0 10px 15px;
    margin-bottom: 10px;
    background-color: $color__grey--light;
    border: $button-border;
    border-top: none;
  }

  &__body {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    &:not(:first-of-type) {
      border-top: 1px dashed $button-border-color;
      margin-top: 25px;
      padding-top: 10px;
    }
  }

  &__header-wrapper {
    width: 100%;

    display: flex;
    align-items: center;
    background-color: $black;
    color: $white;
    height: 50px;

    margin-top: 30px;

    &:first-of-type {
      margin-top: 0;
    }

    &--upsell {
      // background-color: $color__red--barnes;
    }
    &.kiosk {
      background-color: $black;
      color: $white;
    }
  }

  &__header {
    padding: 10px 20px;

    margin: 0;

    width: 100%;

    font-size: 22px;
    font-weight: 200;
  }

  &__icon {
    height: 20px;
    width: 20px;

    &--lock {
      transition: opacity 0.25s ease-in-out;
      float: right;
    }

    &--upsell {
      fill: #fff;
      float: right;
    }
  }

  &__form-flex {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 10px;
  }

  &__input-wrapper {
    width: 100%;
    display: inline-block;
    position: relative;
  }

  &__input {
    -webkit-appearance: none !important;
    -moz-appearance: none !important;
    appearance: none !important;

    display: inline-block;
    width: 100%;

    font-family: Calibre, sans-serif;
    font-size: 22px;
    height: 45px;
    padding: 0 15px;

    border-radius: 0 !important;
    border: $button-border;
    color: $darkestgrey;

    &::placeholder {
      color: $button-border-color;
    }

    &:focus {
      color: $darkestgrey;
    }

    &--error {
      border: 1px solid red;
    }
  }

  &__label {
    line-height: 30px;
    font-weight: 700;
    width: 100%;

    display: inline-block;

    flex: 0 1 100%;
  }

  &__button {
    font-size: 16px;
    line-height: 40px;
    height: 40px;
    font-weight: 600;
    padding-left: 10px;
    padding-right: 10px;
    width: auto;
    margin-bottom: 10px;

    color: $color__copy--medium;
    background-color: transparent;
    border: 2px solid $grey;

    &:active,
    &:hover {
      color: $color__copy--medium;
      background-color: transparent;
      border: 2px solid $grey;
    }
  }

  &__credit-card-logo {
    height: 35px;
    width: 35px;

    position: absolute;
    top: 5.5px;
    right: 10px;
  }

  &__warning {
    @include zeta();

    font-weight: 700;
    color: $orange;

    &:after {
      content: " ";
    }
  }

  &__copy {
    margin: 20px 0 0;
  }

  &__membership-section {
    opacity: 1;
    max-height: 2000px;

    transition: opacity 1s, max-height 0.5s;

    &--disabled {
      opacity: 0;
      max-height: 0;
    }
  }

  &__membership-toggle {
    margin: 20px 0;

    font-size: 18px;
    font-style: italic;

    display: flex;
    flex-direction: row;
    align-items: center;

    .toggle {
      margin-left: 20px;

      @include breakpoint("medium+") {
        margin-left: auto;
      }
    }
  }

  // &__membership-toggle-description {
  //     margin-right: ;
  // }
}
