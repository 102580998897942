@import "../../scss/variables.scss";
@import "../../scss/mixins.scss";

/**
 * 1 the height is set in the variables file. This makes things so much easier
 * and we can use the value across the site’s CSS
 */
.site-header {
  $self: &;

  .site-header__logo {
    fill: $orange;
  }
  position: fixed;
  color: $color__red--barnes !important;
  background-color: $white !important;

  transform: translate3d(0, 0, 0);

  #{ $self } {
    &__nav {
      svg,
      &__btn,
      &__link {
        color: $black;
      }
    }
  }

  height: #{map-get($headerHeights, "xsmall")}; /* 1 */
  left: 0;
  padding: 3px 0 0;
  position: fixed;
  top: 0;
  transform: translate3d(0, 0, 0);
  transition: background 250ms ease, transform 300ms ease;
  z-index: #{map-get($z-indexes, "header")};
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  @include breakpoint("small+") {
    height: #{map-get($headerHeights, "small")}; /* 1 */
  }

  @include breakpoint("medium+") {
    height: #{map-get($headerHeights, "medium")}; /* 1 */
    color: $white !important;
    background-color: transparent !important;
    .site-header__logo {
      fill: $white;
    }
    #{ $self } {
      &__nav {
        svg,
        &__btn,
        &__link {
          color: $white;

          &:hover,
          &--active {
            color: $color__red--barnes;
          }
        }
      }
    }
  }

  @include breakpoint("large+") {
    height: #{map-get($headerHeights, "large")}; /* 1 */
    padding-top: 0;
  }

  @include breakpoint("xlarge+") {
    height: #{map-get($headerHeights, "xlarge")}; /* 1 */
    padding-top: 2px;
  }

  @media print {
    color: $color__copy--dark !important;
    position: relative;
  }

  &__logo {
    color: $white !important;

    @include breakpoint("medium+") {
      margin: 18px 0 0 25px;
    }
    height: 37px;
  }

  &--unlocked {
    .site-header__logo {
      fill: $white !important;
    }
    position: fixed;

    transform: translate3d(0, -1200%, 0);

    @include breakpoint("medium+") {
      transform: translate3d(0, -600%, 0);
    }
  }

  &--locked {
    position: fixed;
    color: $color__red--barnes !important;
    background-color: $white !important;
    transform: translate3d(0, 0, 0);

    #{ $self } {
      &__nav {
        &__btn,
        &__link {
          color: $black;

          &:hover,
          &--active {
            color: $color__red--barnes;
          }
        }
      }
    }

    .log-out{
      color: $color__red--barnes !important;
    }

    .site-header__nav {
      color: $color__red--barnes !important;
      svg {
        color: $color__red--barnes !important;
      }    }

    .a-logo__svg {
      color: $color__red--barnes !important;
    }

    .a-logo__svg--100 {
      color: $color__red--barnes !important;
      @include breakpoint("medium+") {
        transform: translate(0%, -5%);
      }

      @include breakpoint("large+") {
        transform: scale(0.9) translate(0%, -7.5%);
      }

      @include breakpoint("xlarge+") {
        transform: scale(0.9) translate(0%, -7.5%);
      }
    }
  }

  &--vip {
    position: unset;
  }
}

.site-header > .header-container {
  align-items: center;
  display: flex;
  flex-direction: row;
  height: 100%;
  width: 100%;
  justify-content: space-between;
  padding: 0px 25px;

  @include breakpoint("medium+") {
    padding: 0px 40px;
  }

  @include breakpoint("large+") {
    padding: 0px 60px;
  }

  @include breakpoint("xlarge+") {
    padding: 0px 80px;
  }
}

/**
   * List of page links
   */
.site-header__nav {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  @media print {
    display: none;
  }
}

/**
   * Page links
   */
.site-header__nav__link {
  border-bottom: 2px solid transparent;
  color: currentColor;
  font-size: 22px;
  margin: 2px 10px 0;
  text-decoration: none;
  transition: color 150ms ease 50ms;

  @include breakpoint("large-") {
    margin-top: 0;
  }

  @include breakpoint("medium-") {
    display: none;
  }

  // Active page
  &[aria-current] {
    color: $color__red--barnes;
    border-bottom-color: currentColor;
  }

  &:focus:not(.focus-ring) {
    border-bottom-color: currentColor;
  }

  &:hover {
    color: $color__red--barnes;
  }

  &:focus:hover {
    color: currentColor;
  }
}

/**
   * Button e.g. for clicking to show the menu or search option
   */
.site-header__nav__btn {
  color: currentColor;
  margin: 0;
  position: relative;
  top: -2px;
  transition: color 250ms ease-in;

  @include breakpoint("large+") {
    top: 2px;
  }

  &:hover {
    color: $color__red--barnes;
  }

  &:focus:not(.focus-ring) {
    color: $color__red--barnes;
    outline: none;
  }
}

.no-js .site-header__nav__btn {
  display: none;
}

/**
   * Tweak the positioning/spacing of these buttons for utter perfection ;)
   */
.site-header__nav__btn:not(:last-of-type) {
  @include breakpoint("medium-") {
    display: none;
  }
}

.site-header__nav__btn:first-of-type,
.site-header__nav__btn + .site-header__nav__btn {
  margin-left: 30px;
}

.site-header__nav__btn:last-of-type {
  margin-left: 30px;
  margin-right: -2px;
}

.site-header__nav__btn + .site-header__nav__btn {
  margin-top: 0;
}

/**
   * Set the colour of the logo when it appears in the header
   * this is overruled in one of our _state_ scss files e.g. /frontend/scss/state/has-hero.scss
   */
.site-header .a-logo {
  @media print {
    color: $color__copy--dark !important;
  }
}

/**
   * Site title
   */
.site-header__site {
  color: $color__red--barnes;

  @include breakpoint("medium") {
    margin-right: 50px;
    position: relative;
    top: 7px;
  }

  @include breakpoint("large+") {
    margin-bottom: 25px;
  }

  @include breakpoint("xlarge+") {
    margin-bottom: 0;
  }

  @include breakpoint("small-") {
    margin-left: 15px;
    max-width: 50px;
    position: relative;
    top: 4px;
  }
}

.site-header__top-bar {
  color: $color__copy--medium;
  font-size: 18px;
  justify-content: flex-end !important;

  @include breakpoint("small-") {
    right: 0;
    position: absolute;
    top: 13px;
  }

  @include breakpoint("medium") {
    position: relative;
    top: 3px;
  }

  @include breakpoint("xlarge+") {
    position: relative;
    top: 15px;
    z-index: #{map-get($z-indexes, "headerTopBar")};
  }

  a:focus:not(.focus-ring),
  a:not(.btn):not(.a-brand-link):hover {
    color: $color__copy--dark;
  }

  a:focus {
    text-decoration: underline;
  }
}

.site-header__top-bar .site-header__nav__btn {
  color: $color__copy--dark;
  display: block;
  margin-left: 15px;
  margin-top: 10px;

  @include breakpoint("small-") {
    margin-left: 30px;
    position: relative;
    top: -3px;
    z-index: #{map-get($z-indexes, "shopifyHeaderButton")};
  }

  @include breakpoint("medium") {
    bottom: -82px;
    position: absolute;
    right: 40px;
    z-index: #{map-get($z-indexes, "shopifyHeaderButton")};
  }

  @include breakpoint("large+") {
    position: relative;
    top: -1px;
  }

  @include breakpoint("xlarge") {
    display: none;
  }
}

.site-header__home {
  padding-right: 20px;
  padding-top: 10px;

  @include breakpoint("small-") {
    display: none;
  }
}
