@import "../variables.scss";
@import "../mixins.scss";

.carousel {
  padding-top: 10px;

  &__wrapper {
    display: flex;
    .carousel-arrow-wrapper {
      width: 100px;
      background-color: transparent !important;
      border: none;

      .carousel-arrow {
        &--right {
          transform: rotate(0deg);
          &:hover,
          &:active {
            cursor: pointer;
            color: $color__copy--medium;
          }
        }

        &--left {
          transform: rotate(180deg);
          &:hover,
          &:active {
            cursor: pointer;
            color: $color__copy--medium;
          }
        }
      }
    }
  }

  &__content {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    height: fit-content;
    height: 410px;

    @include breakpoint("medium+") {
      padding-top: 16px;
      height: 480px;
    }

    // Scroll bar styling
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }

    --scrollbar-width: 8px;
    --mask-height: 24px;
    overflow-x: auto;
    padding-right: var(--mask-height);
    padding-left: var(--mask-height);
    --mask-image-content: linear-gradient(
      to right,
      transparent,
      black var(--mask-height),
      black calc(100% - var(--mask-height)),
      transparent
    );

    --mask-size-content: calc(100% - var(--scrollbar-width)) 100%;
    --mask-image-scrollbar: linear-gradient(black, black);
    --mask-size-scrollbar: var(--scrollbar-width) 100%;

    mask-image: var(--mask-image-content), var(--mask-image-scrollbar);
    mask-size: var(--mask-size-content), var(--mask-size-scrollbar);
    mask-position: 0 0, 110% 0;
    mask-repeat: no-repeat, no-repeat;
  }
}
