@import "../variables.scss";

.login {
    text-align: center;
    width: 100%;
    margin: 0 auto;
    border: solid 1px $grey;
    padding: 70px 20px;
    margin-top: 60px;

    @media only screen and (min-width: 767px) {
        width: 50%;
    }

    svg {
        width: 70%;
        fill: $orange;
    }

    &__text {
        text-align: center;
        color: $white;

        margin: 0 auto;

        &:hover {
            cursor: pointer;
        }

        @include breakpoint("medium+") {
            width: 80%;
        }
    }

    &__message {
        text-align: center;
        color: $white;
        font-weight: bold;
        font-size: larger;

        margin: 15px auto 0;

        @include breakpoint("medium+") {
            width: 80%;
        }
    }

    &__link {
        width: 80%;
        font-size: 18px;
        color: #fff;
        margin: auto auto 30px;
        text-align: center;
        text-decoration: underline;

        &:hover {
            color: #fff;
        }
    }
}

.login-title {
    margin-bottom: 40px;
}

.login__input {
    // padding: 10px 15px;
    // font-size: 24px;
    // margin: 10px 0;
    // box-sizing: border-box;
    // width: 100%;
    // margin-bottom: 19px;
    // border: solid 1px $grey;

    // &:focus,
    // &:active {
    //     border: solid 1px $orange;
    //     outline: none;
    // }
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border: 2px solid #dcdcdc;
    border-right-width: 0;
    border-radius: 0;
    color: #8c8c8c;
    display: block;
    font-size: 16px;
    height: 60px;
    line-height: 60px;
    padding: 0 22px;

    &--error {
      border: solid 1px $red;
      margin-bottom: 0;
    }
}

.login-email-error {
    color: $red;
    margin-top: 6px;
}