@import "../variables.scss";
@import "../mixins.scss";

.order-confirmation {

    &__container {
        margin: 0px 13px 0px 13px;
        padding: 0 22px;

        &--border {
            margin: 10px 13px 0px 13px;
            border: $button-border;

            padding: 10px 15px;
        }
    }

    &__header {
        margin: 0;
        font-weight: bold;
        font-size: 22px;
        color: $color__copy--medium;
    }

    &__copy-wrapper {
        margin-top: 25px;
        margin-bottom: 20px;
    }

    &__copy {
        margin: 10px 0;
    }

    &__total {
        width: 100%;
        text-align: right;
        font-size: 27px;
        font-weight: 700;
    }

    &__guest-pass-wrapper {
        width: 100%;
        display: flex;
        justify-content: flex-end;
    }

    &__guest-pass {
        @include zeta();
        
        display: block;
        width: 50%;
        text-align: right;
    }
}