@import "../variables.scss";
@import "../mixins.scss";

.settings {
  font-size: 18px;
  max-width: 1000px;

  .card {
    padding: 8px;
  }
  &__text {
    font-size: 19px !important;
    line-height: (30/19) !important;
    text-align: center;
    color: $color__copy--dark !important;
    font-family: FFMiloSerifWebText, serif !important;
    margin-top: 10px;
  }

  &__edit-button {
    position: absolute;
    top: 12px;
    right: 15px;
    border: none;
    margin: none;
    background-color: transparent;

    &:active,
    &:hover {
      svg {
        color: $black;
      }
    }
  }

  &__edit-icon {
    color: $color__copy--medium;
    width: 22px;
    height: 22px;

    &--close {
      width: 20px;
      height: 20px;
    }
  }

  &__description {
    display: block;

    @include description();

    &--border-top {
      margin-top: 10px;
      margin-bottom: 5px;
      padding-top: 10px;
      border-top: $button-border;
    }

    &--notification {
      float: right;

      border: 1px solid $color__red--barnes;
      padding: 2px 5px;
      color: $color__red--barnes-dark;
    }
  }

  &__input {
    @include zeta();

    font-family: Calibre, sans-serif;
    padding: 0 5px;
    color: $black;
  }

  &__toggle-wrapper {
    padding: 8px 0;
    // line-height: 24px;
    line-height: 30px;
  }

  $toggle-width: 45px;
  &__toggle {
    float: right;
    position: relative;
    margin-left: calc(#{$toggle-width} - 10px);

    &::before {
      content: " ";
      display: block;

      position: absolute;
      top: -6px;
      right: -8px;

      width: $toggle-width;
      height: 24px;

      transition: background-color 0.2s ease, border-color 0.2s ease;
      border: 2px solid $button-border-color;
      border-radius: 12px;
      background-color: $button-border-color;
    }

    &::after {
      content: " ";
      position: absolute;
      top: -4px;

      cursor: pointer;
      touch-action: pan-x;

      height: 20px;
      width: 20px;
      border-radius: 50%;
      border: 2px solid $color__copy--medium;
      background-color: $white;

      transition: transform 0.2s ease, border-color 0.2s ease;
      transform: translate3d(-7px, 0, 0);
    }

    &--active {
      &::after {
        transform: translate3d(calc((#{$toggle-width} - 20px) * -1), 0, 0);
        border-color: $color__red--barnes-dark;
      }

      &::before {
        background-color: $color__red--barnes-dark;
        border-color: $color__red--barnes-dark;
      }
    }
  }

  &__button {
    float: right;
    display: inline;
    margin: 0;
    width: auto;

    padding: 5px 10px;

    font-size: 16px;
    font-weight: 300;

    height: 30px;
    line-height: initial;

    &--save {
      display: block;
      float: initial;
      margin-top: 10px;
      margin-left: auto;
    }
  }
}
